import React, { Component } from 'react'

class Footer extends Component {
  render() {
    return (
      <footer style={{ textAlign: 'center' }}>

      </footer>
    )
  }
}

export default Footer
